import { SubmissionError } from 'redux-form'
import axios from 'axios'


function submit(values) {
console.log('submit', values)
    const {email, outputName, templateId, data }= values
    if(!email)
        throw new SubmissionError({
            email: 'Merci de rajouter une adresse email',
            _error: 'no email'
        })
    if(!outputName)
        throw new SubmissionError({
            email: 'Pas outputName',
            _error: 'no outputName'
        })
    if(!templateId)
        throw new SubmissionError({
            email: 'Pas templateId',
            _error: 'no templateId'
        })
    return axios.post('https://us-central1-davidvenink.cloudfunctions.net/generateDocument', {
        templateId,
            email,
        outputName,
        data: [{"{{TON PRENOM}}": "CHARLIE"}, {"{{TON NOM}}": "PAUL"}]
    },
        {
            headers: {'Content-Type': 'application/json' }
        }
    )
        .then(function (response) {
            //handle success
            console.log(response);
            window.alert(`You submitted:\n\n${JSON.stringify(values, null, 2)}`)
        })
        .catch(function (response) {
            //handle error
            console.log(response);
            throw new SubmissionError({
                password: 'Wrong password',
                _error: response
            })
        });

}
export default submit;
