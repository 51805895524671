import { createStore, combineReducers } from 'redux';
import { reducer as reduxFormReducer } from 'redux-form';
import storage from 'redux-persist/lib/storage'
import { persistStore, persistReducer } from 'redux-persist'

const persistConfig = {
    key: 'root',
    storage,
}
const reducer = combineReducers({
    form: reduxFormReducer, // mounted under "form"
});
const persistedReducer = persistReducer(persistConfig, reducer);


export const store = (window.devToolsExtension
    ? window.devToolsExtension()(createStore)
    : createStore)(persistedReducer);

export const persistor = persistStore(store);
