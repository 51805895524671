import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import Form from './App/Form';
import { Provider, createStore } from 'react-redux'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import { store, persistor} from './redux/store'
import './node_modules/bootstrap/dist/css/bootstrap.min.css';
import List from './App/List';
import { PersistGate } from 'redux-persist/integration/react'
import Layout from './App/Layout';
import {ThemeProvider} from '@material-ui/core';
import theme from './App/theme';


export default function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={theme}>
          <Router>
            <Switch>
              <Route exact path="/">
                <List />
              </Route>
              <Route path="/form/:slug">
                  <Form/>
              </Route>
            </Switch>
          </Router>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
