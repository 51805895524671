import React, {useEffect} from 'react';
import {Field, FieldArray, reduxForm} from 'redux-form';
import {connect} from 'react-redux';
import forms, {userSection} from '../const/forms';
import {useParams, withRouter} from "react-router-dom";
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import Typography from '@material-ui/core/Typography';
import Layout from './Layout';
import {compose} from 'redux';
import submit from '../services';
import {DialogTitle} from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";

const validate = values => {
    const errors = {};
};
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            textAlign: 'center'
        },
        button: {
            marginRight: theme.spacing(1),
        },
        instructions: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
        paper: {
            textAlign: 'left',
            maxWidth: '640px',
            padding: '2em',
            margin: 'auto',
            marginBottom: '1em'
        }
    }),
);

const renderField = ({input, label, description, placeholder, type, meta: {touched, error}, ...custom}) => {
    const classes = useStyles();
    return (
        <Paper className={classes.paper} elevation={3}>
            <label>
                <Typography variant="h6" component="h6">
                    {label}
                </Typography>
                {description && <p>{description}</p>}
            </label>
            <div>
                <TextField
                    //label={label}
                    variant="outlined"
                    style={{width: '100%'}}
                    multiline={type === 'long'}
                    //rows={type === 'long' ? 2 : 1}
                    placeholder={placeholder}
                    error={touched && error}
                    helperText={touched && error}
                    {...input}
                    {...custom}
                />
            </div>
        </Paper>
    );
}


const renderQuestions = (values) => {
    console.log({values})
    const {fields, meta: {error}} = values;
    return (
        <>
            {fields.map(({code, title, description, placeholder, type}) => (
                <div key={code}>
                    <Field
                        name={code}
                        type="text"
                        /*format={(value, name)  => {
                            return name == 'outputName' ? 'toto' : value;
                        }*/
                        placeholder={placeholder}
                        description={description}
                        component={renderField}
                        label={title}
                        type={type}
                    />
                </div>
            ))}
            {error && <li className="error">{error}</li>}
        </>
    )
}

const FieldArraysForm = props   => {
    console.log({props});
    let { handleSubmit, pristine, reset, submitting, form, slug, submitSucceeded, clearSubmit, submit, error } = props;
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set<number>());
    const [modal, setModal] = React.useState(false);
    if (!form) return '404';
    form = {...form, sections: [...form.sections, ...[userSection]]}
    const steps = form.sections.map(s => s.title);
    useEffect(() => {
            if(submitSucceeded)
                setModal(true)
        },
        [submitSucceeded]
    )

    const getSteps = (steps: [], step: number) => {
        return steps[step];
    }

    const isStepOptional = (step: number) => {
        return false;
    };

    const isStepSkipped = (step: number) => {
        return skipped.has(step);
    };

    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);

    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSkip = () => {
        if (!isStepOptional(activeStep)) {
            // You probably want to guard against something like this,
            // it should never occur unless someone's actively trying to break something.
            throw new Error("You can't skip a step that isn't optional.");
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped((prevSkipped) => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStep);
            return newSkipped;
        });
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const handleGeneration = () => {
        //ref doc, submit

        //handleSubmit(console.log)
    }


    return (

        <Layout title={form.formTitle}>
            <div className={classes.root}>
                <Stepper style={{backgroundColor: 'transparent'}} activeStep={activeStep} alternativeLabel>
                    {steps.map((label, index) => {
                        const stepProps: { completed?: boolean } = {};
                        const labelProps: { optional?: React.ReactNode } = {};
                        if (isStepOptional(index)) {
                            labelProps.optional = <Typography variant="caption">Optional</Typography>;
                        }
                        if (isStepSkipped(index)) {
                            stepProps.completed = false;
                        }
                        return (
                            <Step key={label} {...stepProps}>
                                <StepLabel {...labelProps}>{label}</StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
                <div>
                    <form onSubmit={handleSubmit}>
                        {error.message}
                            <div>
                                <Typography className={classes.instructions} variant="h5"
                                            component="h5">{getSteps(steps, activeStep)}</Typography>
                                <FieldArray name="questions" component={(values) => renderQuestions({
                                    ...values,
                                    fields: form.sections[activeStep].questions
                                })}/>

                                <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
                                    Back
                                </Button>
                                {isStepOptional(activeStep) && (
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleSkip}
                                        className={classes.button}
                                    >
                                        Skip
                                    </Button>
                                )}
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => submit()}
                                    className={classes.button}
                                >
                                    submit
                                </Button>

                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => reset()}
                                    className={classes.button}
                                >
                                    reset
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => clearSubmit()}
                                    className={classes.button}
                                >
                                    clearSubmit
                                </Button>


                                {activeStep === steps.length - 1 ?
                                    <Button
                                        type={'submit'}
                                        disabled={submitting}
                                        variant="contained"
                                        color="primary"
                                        className={classes.button}
                                    >
                                        Finish
                                    </Button>
                                    : <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={handleNext}
                                            className={classes.button}
                                        >
                                        Next
                                    </Button>
                                    }
                            </div>
                    </form>
                </div>
            </div>
            <Dialog
                open={modal}
                onClose={() => setModal(false)}
            >
                <DialogTitle id="alert-dialog-title">Document généré</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Nous vous avons partager le document par depuis Google Drive
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setModal(false)} color="primary" autoFocus>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </Layout>
    );
};


export default compose(
    withRouter,
    connect(
        (state, {match: {params: {slug}}}) => {
            const selectedForm = forms.find(f => f.slug === slug)
            return {
                form: selectedForm ,
                initialValues: {
                    ...state.form && state.form.allForms && state.form.allForms.values,
                    outputName: selectedForm.formTitle,
                    templateId: selectedForm.googleDocId,
                }
            }
        }
    ),
    reduxForm({
        form: 'allForms',
        destroyOnUnmount: false,
        validate,
        onSubmit: submit
    }),
)(FieldArraysForm)
