import React from 'react';
import Toolbar from "@material-ui/core/Toolbar";
import {
    IconButton,
    Typography,
    AppBar,
    Container,
    Box,
    Breadcrumbs,
    makeStyles,
    createStyles,
    Theme
} from "@material-ui/core";
import {
    Link
} from "react-router-dom";
import { Home, ThreeDRotation } from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        link: {
            display: 'flex',
            color: '#fff',
            '&:hover': {
                color: "#fff",
            },
        },
        icon: {
            marginRight: theme.spacing(0.5),
            width: 20,
            height: 20,
        },
        title: {
            color: '#fff',
            fontWeight: 600,
        },
    }),
);

export default ({title, children}) => {
    const classes = useStyles();
    return (<div style={{backgroundColor: '#f8f8f8', minHeight: '100%'}}>
        <AppBar>
            <Container>
                <Toolbar>
                    <Breadcrumbs>
                        <Link to="/" className={classes.link}>
                            <Home className={classes.icon} />
                            Liste Formulaire
                        </Link>
                        <Typography className={classes.title}>{title}</Typography>
                    </Breadcrumbs>
                </Toolbar>
            </Container>
        </AppBar>
        <Container>
            <Box color="primary" py={10}>
                {children}
            </Box>
        </Container>
    </div>)
}

