export const userSection = {
    title: 'Generation du formulaire',
    questions: [{
        title: "Adresse e-mail",
        code: "email",
        placeholder: "Adresse un e-mail valide",
    }/*, {
        title: "Quel est le nom que vous souhaitez donner pour cette campagne ?",
        code: "outputName",
    }*/]
}


export default [{
    formTitle: 'GENERATEUR ULTIME D\'EMAILS | SEQUENCE DE PRECHAUFFE',
    googleDocId: '18I6LS1i8cvZ2j3FqJ9lE1Hxho0jrnweJR0d2W3appm4',
    slug: '1stform',
    formDescription: "description du formulaire",
    sections: [{
        title: "Informations de base de ton webinaire",
        questions: [{
            title: "Ton prenom pls",
            code: "TON PRENOM",
            placeholder: "placeholder",
            type: "long",
        }, {
            title: "ton nom title",
            description: "second description",
            code: "TON NOM",
            optional: true
        }]
    }, {
        title: "A qui s'adresse le webinaire?",
        questions: [{
            title: "MARRE",
            description: "De quoi ton prospect parfait à LE PLUS MARRE ? \"Mon client idéal en a marre de ____________________\" * Formule ta réponse au \"vous\" (Exemples : ne pas intéresser vos interlocuteurs quand vous vous présentez ; fragiliser votre enfant en cédant malgré vous aux violence éducative ordinaires ; etc.",
            code: "titreWebiner",
            placeholder: "placeholder",
            type: "long",
        }, {
            title: "second title",
            description: "second description",
            code: "secondField",
        }]
    }, {
        title: "Et pour terminer...",
        questions: [{
            title: "MARRE",
            description: "De quoi ton prospect parfait à LE PLUS MARRE ? \"Mon client idéal en a marre de ____________________\" * Formule ta réponse au \"vous\" (Exemples : ne pas intéresser vos interlocuteurs quand vous vous présentez ; fragiliser votre enfant en cédant malgré vous aux violence éducative ordinaires ; etc.",
            code: "titreWebiner",
            placeholder: "placeholder",
            type: "long",
        }, {
            title: "second title",
            description: "second description",
            code: "secondField",
        }]
    }]
}, {
    formTitle: '2em formulaire',
    googleDocId: '18I6LS1i8cvZ2j3FqJ9lE1Hxho0jrnweJR0d2W3appm4',
    slug: '2stform',
    formDescription: "description du formulaire",
    sections: [{
        title: "Informations de base de ton webinaire",
        questions: [{
            title: "first title",
            description: "first description",
            code: "firstField",
            placeholder: "placeholder",
        }, {
            title: "3rd title",
            description: "3rd description",
            code: "3rdField",
        }]
    }]
}]

