import React from "react";
import {
    Link
} from "react-router-dom";
import forms from "../const/forms";
import Layout from "./Layout";
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import {makeStyles, createStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            textAlign: 'center'
        },
        button: {
            marginRight: theme.spacing(1),
        },
        instructions: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
        paper: {
            textAlign: 'left',
            maxWidth: '640px',
            padding: '2em',
            margin: 'auto',
            marginBottom: '1em'
        }
    }),
);

export default List => {
    const classes = useStyles();
    return (
        <Layout>
            {forms.map(f => (
                <Link to={`form/${f.slug}`}>
                    <Card className={classes.paper}>
                        <CardContent>
                            <Typography variant="h6">{f.formTitle}</Typography>
                            {f.formDescription && <Typography variant="caption">{f.formDescription}</Typography>}
                        </CardContent>
                    </Card>
                </Link>
            ))}
        </Layout>
    );
}
